<template>
  <div class="z2CellBGView">
    <device-msg-head
        :order="order"
        device-type="普通资产"
        :device-mac="z2Info.macAddr"
        :device-station-mac="'基站:' + z2Info.baseMac">
    </device-msg-head>
    <div style="height: 5px"></div>
    <device-msg-cell v-for="(key) in dataList" :key="key" :content-str="key + ':' + dataMsg(key)">
    </device-msg-cell>
    <device-msg-footer :rssi="dataMsg('rssi')" :time-stamp="dataMsg('timestamp')"></device-msg-footer>
    <div style="width: 95%;height: 1px;background-color: #cccccc;margin: 5px 0px 5px 0px"></div>
  </div>
</template>

<script>
import DeviceMsgHead from '@/views/deviceMQ/deviceMsg/deviceMsgHead'
import DeviceMsgCell from '@/views/deviceMQ/deviceMsg/deviceMsgCell'
import DeviceMsgFooter from '@/views/deviceMQ/deviceMsg/deviceMsgFooter'
export default {
  name: 'z2Cell',
  components: { DeviceMsgFooter, DeviceMsgCell, DeviceMsgHead },
  props: {
    order:{
      type: String,
      default: () => {
        return '#'
      }
    },
    z2Info: {
      type: Object,
      default: () => {
        return {
          "baseMac": "",
          "id": "",
          "macAddr": "",
          "rssi": 0,
          "status": 0,
          "timestamp": 0
        }
      }
    }
  },
  computed: {
    dataList() {
      return ['baseMac', 'macAddr', 'status']
    },
  },
  methods:{
    dataMsg:function(key) {
      if (typeof this.z2Info[key] == 'undefined' || this.z2Info[key] == null) {
        return ''
      }
      return this.z2Info[key]
    },
  }
}
</script>

<style lang="scss" scoped>

.z2CellBGView {
  width: 100%;
}

</style>
